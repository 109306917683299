import React from 'react';
import './Modal.scss';

const Modal = () => {
  const handleRedirect = () => {
    window.location.href = 'https://dashboard-staff-preprod.monspecialisteauto.com/';
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Dashboard Indisponible</h2>
        <p>
          Le dashboard que vous utilisiez n'est plus disponible. Vous devez maintenant
          utiliser notre nouveau dashboard pour continuer. Pour cela, cliquez sur le bouton ci-dessous.
        </p>
        <button onClick={handleRedirect}>Accéder au nouveau dashboard</button>
      </div>
    </div>
  );
};

export default Modal;
